import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CDataTable,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getList } from "../../api/generics";
import Product, { ProductPriceLog } from "../../models/product";
import { SUCCESS } from "../../utils/constants/tags";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
import { formatDateTime } from "../../utils/dates";
import { defaultValueOnUndefined } from "../../utils/fields";

interface ProductPriceHistoryModalProps {
  show: boolean;
  product?: Product;
  onCancel: () => void | Promise<void>;
}

const ProductPriceHistoryModal: React.FC<ProductPriceHistoryModalProps> = ({
  show,
  product,
  onCancel,
}) => {
  const fields = [
    {
      key: "createdAt",
      _classes: ["text-center"],
      label: "Fecha",
    },
    {
      key: "categoryName",
      _classes: ["text-center"],
      label: "Categoría",
    },
    {
      key: "price",
      _classes: ["text-center"],
      label: "Precio",
    },
    {
      key: "wholesalePrice",
      _classes: ["text-center"],
      label: "Precio Mayorista",
    },
    {
      key: "superWholesalePrice",
      _classes: ["text-center"],
      label: "Precio Super Mayorista",
    },
    {
      key: "productionCost",
      _classes: ["text-center"],
      label: "costo Producción",
    },
  ];

  const [logItems, setLogItems] = useState<ProductPriceLog[]>([]);
  const [loading, setLoading] = useState(false);
  const company = useSelector((state: RootState) => state.company.data.company);

  const getLogItems = async () => {
    if (product === undefined || product.id === undefined) {
      setLogItems([]);
      return;
    }
    const productIdStr = product.id.toString();
    if (productIdStr === undefined) {
      setLogItems([]);
      return;
    }
    const limit = 2000;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();
    additionalParams.set("company_id", company.id!.toString());
    additionalParams.set("product_id", productIdStr);
    setLoading(true);
    const logItemsStatus = await getList<ProductPriceLog>(
      `/clothing_products/price_logs/`,
      limit,
      offset,
      additionalParams
    );
    if (logItemsStatus.status === SUCCESS) {
      if (logItemsStatus.data !== undefined) {
        setLogItems(logItemsStatus.data.items);
      } else {
        setLogItems([]);
        setLoading(false);
        return;
      }
    } else {
      setLogItems([]);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setLogItems([]);
  };

  useEffect(() => {
    getLogItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (loading) {
    return (
      <Spinner
        animation="grow"
        style={{
          height: "17px",
          width: "17px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "10px",
        }}
      />
    );
  }

  return (
    <CModal show={show} className="modal-info" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Historial de Precios</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={logItems}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    price: (item: ProductPriceLog) => {
                      return (
                        <td className="text-center">
                          {item.price ? formatToCurrency(item.price, PYG) : "-"}
                        </td>
                      );
                    },
                    categoryName: (item: ProductPriceLog) => {
                      return (
                        <td className="text-center">
                          {defaultValueOnUndefined("-", item.categoryName)}
                        </td>
                      );
                    },
                    wholesalePrice: (item: ProductPriceLog) => {
                      return (
                        <td className="text-center">
                          {item.wholesalePrice
                            ? formatToCurrency(item.wholesalePrice, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    superWholesalePrice: (item: ProductPriceLog) => {
                      return (
                        <td className="text-center">
                          {item.superWholesalePrice
                            ? formatToCurrency(item.superWholesalePrice, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    productionCost: (item: ProductPriceLog) => {
                      return (
                        <td className="text-center">
                          {item.productionCost
                            ? formatToCurrency(item.productionCost, PYG)
                            : "-"}
                        </td>
                      );
                    },
                    createdAt: (item: ProductPriceLog) => {
                      return (
                        <td className="text-center">
                          {item.createdAt
                            ? formatDateTime(new Date(item.createdAt))
                            : "-"}
                        </td>
                      );
                    },
                  }}
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </CModalFooter>
    </CModal>
  );
};

export default ProductPriceHistoryModal;
