import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import ProductionOrder from "../../models/production-order";

interface ProductionOrderResetModalProps {
  show: boolean;
  productionOrder?: ProductionOrder;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ProductionOrderResetModal: React.FC<ProductionOrderResetModalProps> = ({
  show,
  productionOrder,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = productionOrder
    ? `Está seguro de que quiere reestablecer la orden de producción "${productionOrder.productCodeIdentifier} - ${productionOrder.productName}"`
    : "";

  const onConfirmClick = async () => {
    if (productionOrder !== undefined) {
      setSubmitting(true);
      const supplierStatus = await updateItem<ProductionOrder>(
        `/production/${productionOrder.id}/reset/`,
        productionOrder
      );

      if (supplierStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (supplierStatus.detail !== undefined) {
          message = supplierStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Reestablecer</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <h3 className="text-danger">Precaución</h3>
              <h5>
                La orden de producción volverá a su estado antes de iniciar.
                todas las operaciones realizadas sobre ella seran revertidas.
              </h5>
              <br />
              <h5>Podrían perderse datos.</h5>
              <h5>
                Una vez reestablecida podrá borrar esta orden por completo.
              </h5>
              <h5>Esta acción no se puede deshacer.</h5>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onConfirmClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Reestableciendo..." : "Reestablecer"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ProductionOrderResetModal;
