import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
  CBadge,
  CSelect,
  CLink,
  CTooltip,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import InventoryTransferDeleteModal from "./InventoryTransferDeleteModal";
import { formatDateTime } from "../../utils/dates";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {
  INVENTORY_TRANSFERS_CREATE,
  INVENTORY_TRANSFERS_DESTROY,
  INVENTORY_TRANSFERS_RETRIEVE,
  INVENTORY_TRANSFERS_SEND,
  INVENTORY_TRANSFERS_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import InventoryTransfer, {
  INVENTORYTRANSFERSTATUSES,
  getStatusClass,
  getStatusLabel,
} from "../../models/inventory-transfer";
import {
  inventoryTransferIsAcceptable,
  inventoryTransferIsEditable,
  inventoryTransferIsPending,
  inventoryTransferIsReversible,
} from "../../utils/inventory-transfers";
import InventoryTransferStartModal from "./InventoryTransferStartModal";
import InventoryTransferRevertModal from "./InventoryTransferRevertModal";
import InventoryTransferAcceptModal from "./InventoryTransferAcceptModal";
import InventoryLocation from "../../models/inventory-location";
import HasAnyPermissionComponent from "../permissions/HasAnyPermissionComponent";

const ITEMS_PER_PAGE = 10;

const InventoryTransferList = () => {
  const fields = [
    {
      key: "id",
      _classes: ["text-center"],
      label: "N° de Orden",
    },
    {
      key: "sendDate",
      _classes: ["text-center"],
      label: "Fecha de envío",
    },
    {
      key: "receiveDate",
      _classes: ["text-center"],
      label: "Fecha de recepción",
    },
    {
      key: "status",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "senderUserName",
      _classes: ["text-center"],
      label: "Creó/Envió",
    },
    {
      key: "receiverUserName",
      _classes: ["text-center"],
      label: "Recepcionó",
    },
    {
      key: "originName",
      _classes: ["text-center"],
      label: "Origen",
    },
    {
      key: "destinationName",
      _classes: ["text-center"],
      label: "Destino",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [
    showInventoryTransferDeleteModal,
    setShowInventoryTransferDeleteModal,
  ] = useState(false);

  const [inventoryTransfer, setInventoryTransfer] = useState<
    InventoryTransfer | undefined
  >(undefined);
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");
  const [status, setStatus] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [inventoryTransfers, setInventoryTransfers] = useState<
    InventoryTransfer[]
  >([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const [showInventoryTransferStartModal, setShowInventoryTransferStartModal] =
    useState(false);

  const [
    showInventoryTransferRevertModal,
    setShowInventoryTransferRevertModal,
  ] = useState(false);

  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const [factoryLocation, setFactoryLocation] = useState<
    InventoryLocation | undefined
  >(undefined);

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let orderStatus = urlParams.get("order_status")
      ? urlParams.get("order_status")
      : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (orderStatus !== "") map.set("order_status", orderStatus);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    return map;
  };

  const fetchInventoryTransfers = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const inventoryTransfersStatus = await getList<InventoryTransfer>(
      "/inventory_transfers/",
      limit,
      offset,
      urlParams
    );
    if (inventoryTransfersStatus.status === SUCCESS) {
      if (inventoryTransfersStatus.data !== undefined) {
        const count = inventoryTransfersStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setInventoryTransfers(inventoryTransfersStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = inventoryTransfersStatus.detail
        ? inventoryTransfersStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const fetchFactoryLocation = async () => {
    const factoryStatus = await getItem<InventoryLocation>(
      `/inventory_locations/factory_location/`
    );
    if (factoryStatus.status === SUCCESS) {
      if (factoryStatus.data !== undefined) {
        setFactoryLocation(factoryStatus.data);
      }
    } else {
      const message = factoryStatus.detail
        ? factoryStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("order_status")) {
      urlParams.set("order_status", previousParams.get("order_status"));
    }
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value);
  };

  const onAcceptClick = (clickedInventoryTransfer: InventoryTransfer) => {
    setInventoryTransfer(clickedInventoryTransfer);
    setShowAcceptModal(true);
  };

  const onAcceptCancel = () => {
    setInventoryTransfer(undefined);
    setShowAcceptModal(false);
  };

  const onAcceptSuccess = () => {
    successAlert("Recepcion de mercadeías exitosa!");
    setShowAcceptModal(false);
    setInventoryTransfers([]);
    setLoading(true);
    fetchInventoryTransfers();
  };

  const onStartClick = (clickedInventoryTransfer: InventoryTransfer) => {
    setInventoryTransfer(clickedInventoryTransfer);
    setShowInventoryTransferStartModal(true);
  };

  const onRevertClick = (clickedInventoryTransfer: InventoryTransfer) => {
    setInventoryTransfer(clickedInventoryTransfer);
    setShowInventoryTransferRevertModal(true);
  };

  const onUpdateClick = (clickedInventoryTransfer: InventoryTransfer) => {
    const path = getRoutePath("inventoryTransferFormUpdate");
    const modifiedWithId = path.replace(
      ":id",
      String(clickedInventoryTransfer.id)
    );
    history.push(modifiedWithId);
  };

  const onDeleteClick = (clickedInventoryTransfer: InventoryTransfer) => {
    setInventoryTransfer(clickedInventoryTransfer);
    setShowInventoryTransferDeleteModal(true);
  };

  const onInventoryTransferStartCancel = () => {
    setInventoryTransfer(undefined);
    setShowInventoryTransferStartModal(false);
  };

  const onInventoryTransferRevertCancel = () => {
    setInventoryTransfer(undefined);
    setShowInventoryTransferRevertModal(false);
  };

  const onSaleOrderDeleteCancel = () => {
    setInventoryTransfer(undefined);
    setShowInventoryTransferDeleteModal(false);
  };

  const onInventoryTransferStartSuccess = () => {
    successAlert("Envío Iniciado con éxito!");
    setShowInventoryTransferStartModal(false);
    setInventoryTransfers([]);
    setLoading(true);
    fetchInventoryTransfers();
  };

  const onInventoryTransferRevertSuccess = () => {
    successAlert("Envío revertido con éxito!");
    setShowInventoryTransferRevertModal(false);
    setInventoryTransfers([]);
    setLoading(true);
    fetchInventoryTransfers();
  };

  const onSaleOrderDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowInventoryTransferDeleteModal(false);
    setInventoryTransfers([]);
    setLoading(true);
    fetchInventoryTransfers();
  };

  const onPDFClick = async (clickedInventoryTransfer: InventoryTransfer) => {
    const pdfState = await getItem<{ url: string }>(
      `/inventory_transfers/${clickedInventoryTransfer.id}/pdf/`
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(pdfState.data.url, "_blank");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (status !== "") {
      urlParams.set("order_status", status);
    } else {
      urlParams.delete("order_status");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchInventoryTransfers();
    fetchFactoryLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("inventoryTransfers")) {
        return;
      }
      fetchInventoryTransfers();
      fetchFactoryLocation();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Envío de productos a tiendas</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={INVENTORY_TRANSFERS_CREATE}
                >
                  <CLink to={getRoutePath("inventoryTransferForm")}>
                    <CButton color="primary" className="float-right">
                      <BsFillPlusCircleFill />
                      &nbsp; Agregar Nuevo
                    </CButton>
                  </CLink>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol>
                  <CSelect
                    type="text"
                    defaultValue={status !== null ? status : ""}
                    onChange={onStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Object.entries(INVENTORYTRANSFERSTATUSES).map(
                      ([key, entry], ix) => {
                        return (
                          <option key={ix} value={key}>
                            {entry.name}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca la tienda o nro de orden"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={inventoryTransfers}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    id: (item: InventoryTransfer) => {
                      return (
                        <td className="text-center">
                          <span className="">
                            {item.id ? `#${item.id}` : "-"}
                          </span>
                        </td>
                      );
                    },
                    status: (item: InventoryTransfer) => {
                      return (
                        <td className="text-center">
                          <CBadge
                            color={
                              item.status
                                ? getStatusClass(item.status)
                                : undefined
                            }
                          >
                            {item.status
                              ? getStatusLabel(item.status)
                              : undefined}
                          </CBadge>
                        </td>
                      );
                    },
                    sendDate: (item: InventoryTransfer) => {
                      return (
                        <td className="text-center">
                          {item.sendDate
                            ? formatDateTime(new Date(item.sendDate))
                            : "-"}
                        </td>
                      );
                    },
                    receiverUserName: (item: InventoryTransfer) => {
                      return (
                        <td className="text-center">
                          {item.receiverUserName ? item.receiverUserName : "-"}
                        </td>
                      );
                    },
                    receiveDate: (item: InventoryTransfer) => {
                      return (
                        <td className="text-center">
                          {item.receiveDate
                            ? formatDateTime(new Date(item.receiveDate))
                            : "-"}
                        </td>
                      );
                    },
                    actions: (item: InventoryTransfer) => {
                      const reportButton = (
                        <CTooltip content="PDF">
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onPDFClick(item);
                            }}
                          >
                            <i className="fa fa-file-pdf"></i>
                          </CButton>
                        </CTooltip>
                      );
                      const startButton = (
                        <HasAnyPermissionComponent
                          permissionNames={[
                            INVENTORY_TRANSFERS_UPDATE,
                            INVENTORY_TRANSFERS_SEND,
                          ]}
                        >
                          <CTooltip content={"Iniciar Envío"}>
                            <CButton
                              className="text-white"
                              color="primary"
                              onClick={() => {
                                onStartClick(item);
                              }}
                            >
                              <i className="fa fa-arrow-right"></i>
                            </CButton>
                          </CTooltip>
                        </HasAnyPermissionComponent>
                      );
                      const revertButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_TRANSFERS_UPDATE}
                        >
                          <CTooltip content={"Revertir"}>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onRevertClick(item);
                              }}
                            >
                              <i className="fa fa-rotate-left"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_TRANSFERS_UPDATE}
                        >
                          <CTooltip content={"Editar"}>
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const detailButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_TRANSFERS_RETRIEVE}
                        >
                          <CTooltip content={"Detalles"}>
                            <CButton
                              className="text-white"
                              color="primary"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-eye"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_TRANSFERS_DESTROY}
                        >
                          <CTooltip content={"Borrar"}>
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );

                      const acceptButton = (
                        <HasAnyPermissionComponent
                          permissionNames={[
                            INVENTORY_TRANSFERS_UPDATE,
                            INVENTORY_TRANSFERS_SEND,
                          ]}
                        >
                          <CTooltip content={"Recepcionar"}>
                            <CButton
                              className="text-white"
                              color="success"
                              onClick={() => {
                                onAcceptClick(item);
                              }}
                            >
                              <i className="fa fa-arrow-left"></i>
                            </CButton>
                          </CTooltip>
                        </HasAnyPermissionComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {reportButton}
                            {inventoryTransferIsPending(item) ? (
                              startButton
                            ) : (
                              <></>
                            )}
                            {inventoryTransferIsAcceptable(
                              item,
                              factoryLocation?.id
                            ) ? (
                              acceptButton
                            ) : (
                              <></>
                            )}
                            {inventoryTransferIsEditable(item)
                              ? editButton
                              : detailButton}
                            {inventoryTransferIsPending(item) ? (
                              deleteButton
                            ) : inventoryTransferIsReversible(item) ? (
                              revertButton
                            ) : (
                              <></>
                            )}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <InventoryTransferDeleteModal
        show={showInventoryTransferDeleteModal}
        onCancel={onSaleOrderDeleteCancel}
        onSuccess={onSaleOrderDeleteSuccess}
        inventoryTransfer={inventoryTransfer}
      />
      <InventoryTransferStartModal
        show={showInventoryTransferStartModal}
        onCancel={onInventoryTransferStartCancel}
        onSuccess={onInventoryTransferStartSuccess}
        inventoryTransfer={inventoryTransfer}
      />
      <InventoryTransferRevertModal
        show={showInventoryTransferRevertModal}
        onCancel={onInventoryTransferRevertCancel}
        onSuccess={onInventoryTransferRevertSuccess}
        inventoryTransfer={inventoryTransfer}
      />
      <InventoryTransferAcceptModal
        show={showAcceptModal}
        onCancel={onAcceptCancel}
        onSuccess={onAcceptSuccess}
        inventoryTransfer={inventoryTransfer}
      />
    </>
  );
};

export default InventoryTransferList;
