import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CInput,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import InventoryInputBatch from "../../models/inventory-input-batch";
import InventoryInputBatchUpdateModal from "./InventoryInputBatchUpdateModal";
import InventoryInputBatchDeleteModal from "./InventoryInputBatchDeleteModal";
import { formatDateTime } from "../../utils/dates";
import { formatToCurrency } from "../../currency/format";
import {
  INVENTORY_INPUT_BATCHES_CREATE,
  INVENTORY_INPUT_BATCHES_DESTROY,
  INVENTORY_INPUT_BATCHES_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
const ITEMS_PER_PAGE = 10;

const InventoryInputBatchCrud = () => {
  const fields = [
    {
      key: "inventory",
      _classes: ["text-center"],
      label: "Inventario",
    },
    {
      key: "inputMaterialIdentifier",
      _classes: ["text-center"],
      label: "Materia Prima",
    },
    {
      key: "purchaseDate",
      _classes: ["text-center"],
      label: "Fecha de Compra",
    },
    {
      key: "supplierName",
      _classes: ["text-center"],
      label: "Proveedor",
    },
    {
      key: "amount",
      _classes: ["text-center"],
      label: "Cant.",
    },
    {
      key: "unitPrice",
      _classes: ["text-center"],
      label: "Precio Un.",
    },
    {
      key: "totalPrice",
      _classes: ["text-center"],
      label: "Precio Tot.",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [item, setItem] = useState<InventoryInputBatch | undefined>(undefined);
  const [search, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string | moment.Moment>("");
  const [endDate, setEndDate] = useState<string | moment.Moment>("");

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<InventoryInputBatch[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let startDate = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : "";
    let endDate = urlParams.get("end_date") ? urlParams.get("end_date") : "";
    let map = new Map();
    map.set("search", search);
    map.set("page", page);
    if (startDate !== "") map.set("start_date", startDate);
    if (endDate !== "") map.set("end_date", endDate);
    return map;
  };

  const fetchItems = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.get("page") - 1);
    const requetStatus = await getList<InventoryInputBatch>(
      "/inventory_locations/batches/",
      limit,
      offset,
      urlParams
    );
    if (requetStatus.status === SUCCESS) {
      if (requetStatus.data !== undefined) {
        const count = requetStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setItems(requetStatus.data.items);
        setCurrentPage(urlParams.get("page"));
      }
    } else {
      const message = requetStatus.detail
        ? requetStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "search",
      previousParams.get("search") ? previousParams.get("search") : ""
    );
    if (previousParams.get("start_date")) {
      urlParams.set("start_date", previousParams.get("start_date"));
    }
    if (previousParams.get("end_date")) {
      urlParams.set("end_date", previousParams.get("end_date"));
    }
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowEditModal(true);
  };

  const onUpdateClick = (item: InventoryInputBatch) => {
    setItem(item);
    setShowEditModal(true);
  };

  const onDeleteClick = (item: InventoryInputBatch) => {
    setItem(item);
    setShowDeleteModal(true);
  };

  const onEditCancel = () => {
    setItem(undefined);
    setShowEditModal(false);
  };

  const onEditSuccess = (close: boolean) => {
    successAlert("Datos guardados con éxito!");
    setShowEditModal(!close);
    setItems([]);
    setLoading(true);
    fetchItems();
  };

  const onDeleteCancel = () => {
    setItem(undefined);
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDeleteModal(false);
    setItems([]);
    setLoading(true);
    fetchItems();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onStartDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setStartDate("");
      return;
    }
    setStartDate(value);
  };

  const onEndDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      setEndDate("");
      return;
    }
    setEndDate(value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    if (search !== "") {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }
    if (moment.isMoment(startDate)) {
      urlParams.set("start_date", startDate.toISOString());
    } else if (startDate !== undefined && startDate !== "") {
      urlParams.set("start_date", startDate);
    } else {
      urlParams.delete("start_date");
    }
    if (moment.isMoment(endDate)) {
      urlParams.set("end_date", endDate.toISOString());
    } else if (endDate !== undefined && endDate !== "") {
      urlParams.set("end_date", endDate);
    } else {
      urlParams.delete("end_date");
    }
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("inventoryInputBatches")) {
        return;
      }
      fetchItems();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Tandas</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={INVENTORY_INPUT_BATCHES_CREATE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow>
                <CCol></CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDatehange}
                    value={startDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDatehange}
                    value={endDate}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Buscar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={items}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    inventory: (item: InventoryInputBatch) => (
                      <td className="text-center">
                        {item.inventoryLocation!.identifier}
                      </td>
                    ),
                    purchaseDate: (item: InventoryInputBatch) => {
                      return (
                        <td className="text-center">
                          {formatDateTime(new Date(item.purchaseDate!))}
                        </td>
                      );
                    },
                    unitPrice: (item: InventoryInputBatch) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.unitPrice!, "PYG")}
                        </td>
                      );
                    },
                    totalPrice: (item: InventoryInputBatch) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.totalPrice!, "PYG")}
                        </td>
                      );
                    },
                    actions: (item: InventoryInputBatch) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_INPUT_BATCHES_UPDATE}
                        >
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );
                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={INVENTORY_INPUT_BATCHES_DESTROY}
                        >
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <InventoryInputBatchUpdateModal
        show={showEditModal}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
        item={item}
      />
      <InventoryInputBatchDeleteModal
        show={showDeleteModal}
        onCancel={onDeleteCancel}
        onSuccess={onDeleteSuccess}
        item={item}
      />
    </>
  );
};

export default InventoryInputBatchCrud;
