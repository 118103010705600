import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import Datetime from "react-datetime";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getList,
  getItem,
} from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import {
  UniqueListItem,
  listToUniqueListItems,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import InventoryTransferItemForm from "./InventoryTransferItemForm";
import InventoryTransfer, {
  InventoryTransferItem,
} from "../../models/inventory-transfer";
import InventoryLocationSelect from "../inventory-location/InventoryLocationSelect";
import InventoryLocation, {
  INVENTORY_LOCATION_TYPE_PRODUCTS,
} from "../../models/inventory-location";
import {
  InventoryTransferFormItem,
  inventoryTransferIsEditable,
  inventoryTransferAPIReceivedToTableFormat,
  inventoryTransferTableFormatToAPIReceived,
  InventoryTransferFormItemSize,
} from "../../utils/inventory-transfers";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";
import Variant from "../../models/variant";
import StoreRequest from "../../models/store-requests";

interface InventoryTransferFormProps {
  initialInventoryTransfer?: InventoryTransfer;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = [
  "Prenda",
  "Color",
  "Cant. Total",
  "Cost. unit",
  "SubTotal",
];

const InventoryTransferForm: React.FC<InventoryTransferFormProps> = ({
  initialInventoryTransfer,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const company = useSelector((state: RootState) => state.company.data.company);
  const currentUser = useSelector((state: RootState) => state.user);
  const [editingInventoryTransfer, setEditingInventoryTransfer] =
    useState<InventoryTransfer>(
      initialInventoryTransfer ? initialInventoryTransfer : {}
    );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [editable, setEditable] = useState(true);
  const [showStoreRequestsButton, setShowStoreRequestsButton] = useState(true);
  const [sendDate, setSendDate] = useState<string | moment.Moment>("");
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [originLocation, setOriginLocation] =
    useState<InventoryLocation | null>(null);
  const [destinationLocation, setDestinationLocation] =
    useState<InventoryLocation | null>(null);
  const [tableColumns, setTableColumns] = useState<string[]>(FIXED_COLUMNS);

  const [inventoryTransferItems, setInventoryTransderItems] = useState<
    UniqueListItem<InventoryTransferFormItem>[]
  >([]);

  const [amountTobeAdded, setAmountToBeAdded] = useState<number>(1);
  const [barcodeTobeAdded, setBarcodeToBeAdded] = useState<string>("");

  const barcodeInputRef = useRef<HTMLInputElement>(null);

  const onSendDatehange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    setSendDate(value);

    if (moment.isMoment(value)) {
      setEditingInventoryTransfer({
        ...editingInventoryTransfer,
        sendDate: value.toISOString(),
      });
    } else if (value !== undefined && value !== "") {
      setEditingInventoryTransfer({
        ...editingInventoryTransfer,
        sendDate: value,
      });
    }
  };

  const onOriginChange = (newLocation: InventoryLocation | null) => {
    setOriginLocation(newLocation);
    setEditingInventoryTransfer({
      ...editingInventoryTransfer,
      originInventoryId: newLocation !== null ? newLocation.id : undefined,
      originInventory: newLocation !== null ? newLocation : undefined,
      originName: newLocation !== null ? newLocation.identifier : undefined,
    });

    if (
      !destinationLocation ||
      newLocation === null ||
      newLocation.id === destinationLocation.id ||
      !newLocation.isFactory
    ) {
      setShowStoreRequestsButton(false);
      return;
    }
    setShowStoreRequestsButton(true);
  };

  const onDestinationChange = (newLocation: InventoryLocation | null) => {
    setDestinationLocation(newLocation);
    setEditingInventoryTransfer({
      ...editingInventoryTransfer,
      destinationInventoryId: newLocation !== null ? newLocation.id : undefined,
      destinationInventory: newLocation !== null ? newLocation : undefined,
      destinationName:
        newLocation !== null ? newLocation.identifier : undefined,
    });

    if (
      !originLocation ||
      newLocation === null ||
      newLocation.id === originLocation.id ||
      !originLocation.isFactory ||
      newLocation.isFactory
    ) {
      setShowStoreRequestsButton(false);
      return;
    }

    setShowStoreRequestsButton(true);
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingInventoryTransfer({
      ...editingInventoryTransfer,
      senderObs: e.target.value,
    });
  };

  const calculateTotalQuantity = (
    tableItems: UniqueListItem<InventoryTransferFormItem>[]
  ): number => {
    return tableItems.reduce(
      (total, entry) =>
        total +
        (entry.item.sizes?.reduce(
          (total, entry) => total + (entry.amount || 0),
          0
        ) || 0),
      0
    );
  };

  const onAddItemClick = useCallback(() => {
    const newInventoryTransferItems = [
      ...inventoryTransferItems,
      newUniqueListItem<InventoryTransferFormItem>({}),
    ];

    setInventoryTransderItems(newInventoryTransferItems);
    generateTableHeaders(newInventoryTransferItems);
  }, [inventoryTransferItems]);

  const onInventoryTransferFormItemEntryChange = useCallback(
    (inventoryTransferFormItem: UniqueListItem<InventoryTransferFormItem>) => {
      let newInventoryTransferItems = [...inventoryTransferItems];
      const index = newInventoryTransferItems.findIndex((item) => {
        return item.uuid === inventoryTransferFormItem.uuid;
      });

      if (index === -1) {
        setInventoryTransderItems(newInventoryTransferItems);
        return;
      }
      if (
        inventoryTransferFormItem.item.colorId !==
        newInventoryTransferItems[index].item.colorId
      ) {
        const sameProductAndColorIndex = newInventoryTransferItems.findIndex(
          (item) => {
            return (
              item.item.clothingProductId ===
                inventoryTransferFormItem.item.clothingProductId &&
              item.item.colorId === inventoryTransferFormItem.item.colorId
            );
          }
        );
        if (sameProductAndColorIndex !== -1) {
          warningAlert(
            "Este producto en este color ya fue agregado previamente a la lista"
          );
        }
      }
      newInventoryTransferItems[index] = inventoryTransferFormItem;

      let calculatedTotalQuantity = calculateTotalQuantity(
        newInventoryTransferItems
      );
      setTotalQuantity(calculatedTotalQuantity);

      setInventoryTransderItems(newInventoryTransferItems);

      generateTableHeaders(newInventoryTransferItems);

      setEditingInventoryTransfer({
        ...editingInventoryTransfer,
        totalValue: calculateTotalAmount(newInventoryTransferItems),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inventoryTransferItems]
  );

  const onInventoryTransferFormItemEntryDelete = useCallback(
    (entry: UniqueListItem<InventoryTransferFormItem>) => {
      let newInventoryTransferItems = [...inventoryTransferItems];
      const index = newInventoryTransferItems.findIndex((item) => {
        return item.uuid === entry.uuid;
      });

      if (index === -1) {
        setInventoryTransderItems(newInventoryTransferItems);
      }

      newInventoryTransferItems.splice(index, 1);
      let calculatedTotalQuantity = calculateTotalQuantity(
        newInventoryTransferItems
      );
      setTotalQuantity(calculatedTotalQuantity);
      setInventoryTransderItems(newInventoryTransferItems);

      generateTableHeaders(newInventoryTransferItems);
      setEditingInventoryTransfer({
        ...editingInventoryTransfer,
        totalValue: calculateTotalAmount(newInventoryTransferItems),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inventoryTransferItems]
  );

  const onBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcodeToBeAdded(e.target.value);
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmountToBeAdded(Number(e.target.value));
  };

  const onBarcodeSend = async () => {
    if (barcodeTobeAdded === "") {
      errorAlert("Debe Completar el campo codigo de barras");
      return;
    }
    await fetchVariantFromBarcode(barcodeTobeAdded);
  };

  const fetchVariantFromBarcode = async (barcode: string) => {
    barcode = barcode.trim().replace(/[\/\s]/g, "");
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    const variantStatus = await getItem<Variant>(
      `/product_variants/barcode/${barcode}/`,
      additionalParams
    );
    if (variantStatus.status === SUCCESS) {
      if (variantStatus.data === undefined) {
        errorAlert("Ocurrió un error");
        setBarcodeToBeAdded("");
        setAmountToBeAdded(1);
        return;
      }
      processVariantToList(variantStatus.data, amountTobeAdded);
    } else {
      const message = variantStatus.detail
        ? variantStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }

    setBarcodeToBeAdded("");
    setAmountToBeAdded(1);
    if (barcodeInputRef.current !== null) {
      barcodeInputRef.current.focus();
    }
  };

  const onBarcodeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && barcodeTobeAdded !== "") {
      onBarcodeSend();
    }
  };

  const processVariantToList = useCallback(
    async (variant: Variant, amountReceived: number) => {
      const existingItemIndex = inventoryTransferItems.findIndex(
        (entry) =>
          entry.item.clothingProductId === variant.productId &&
          entry.item.colorId === variant.colorId
      );

      let newInventoryTransferItems;

      if (existingItemIndex !== -1) {
        newInventoryTransferItems = [...inventoryTransferItems];
        const existingItem = newInventoryTransferItems[existingItemIndex];

        const updatedSizes: InventoryTransferFormItemSize[] =
          existingItem.item.sizes?.map((size) => {
            if (size.sizeId === variant.sizeId) {
              return {
                ...size,
                amount: (size.amount || 0) + amountReceived,
              };
            }
            return size;
          }) || [];

        newInventoryTransferItems[existingItemIndex] = {
          ...existingItem,
          item: {
            ...existingItem.item,
            sizes: updatedSizes,
            allSizesAmount: updatedSizes.reduce(
              (total, size) => total + (size.amount || 0),
              0
            ),
          },
        };
      } else {
        let newSizes: InventoryTransferFormItemSize[] =
          await fetchAvailableSizesForEntry(
            variant.productId!,
            variant.colorId!
          );

        if (newSizes.length === 0) {
          return;
        }

        const firstSizeVariant = newSizes[0].variant;

        newSizes = newSizes.map((size) => {
          if (size.sizeId === variant.sizeId) {
            return {
              ...size,
              amount: (size.amount || 0) + amountReceived,
            };
          }
          return size;
        });

        const newItem = newUniqueListItem<InventoryTransferFormItem>({
          companyId: firstSizeVariant?.companyId,
          clothingProductId: firstSizeVariant?.productId,
          clothingProduct: firstSizeVariant?.product,
          productCodeIdentifier: firstSizeVariant?.productCodeIdentifier,
          productName: firstSizeVariant?.productName,
          colorName: firstSizeVariant?.color,
          color: firstSizeVariant?.colorObject,
          colorId: firstSizeVariant?.colorId,
          unitValue: firstSizeVariant?.productionCost,
          sizes: newSizes,
          allSizesAmount: newSizes.reduce(
            (total, size) => total + (size.amount || 0),
            0
          ),
        });
        newInventoryTransferItems = [...inventoryTransferItems, newItem];
      }
      let calculatedTotalQuantity = calculateTotalQuantity(
        newInventoryTransferItems
      );
      setTotalQuantity(calculatedTotalQuantity);
      setInventoryTransderItems(newInventoryTransferItems);
      generateTableHeaders(newInventoryTransferItems);
      setEditingInventoryTransfer({
        ...editingInventoryTransfer,
        totalValue: calculateTotalAmount(newInventoryTransferItems),
      });
    },
    [inventoryTransferItems, editingInventoryTransfer]
  );

  const fetchAvailableSizesForEntry = async (
    productId: number,
    colorId: number
  ) => {
    const productIdStr = productId.toString();
    const colorIdStr = colorId.toString();
    const limit = 200;
    const offset = 0;
    const additional = new Map();
    additional.set("clothing_product_id", productIdStr);
    additional.set("color_id", colorIdStr);

    try {
      const sizesStatus = await getList<Variant>(
        "/product_variants/with_related/",
        limit,
        offset,
        additional
      );

      if (sizesStatus.status === SUCCESS && sizesStatus.data !== undefined) {
        const newSizes = sizesStatus.data.items.map((variant) => ({
          size: variant.sizeObject,
          sizeId: variant.sizeId,
          sizeName: variant.size,
          variant: variant,
          variantId: variant.id,
          amount: undefined,
        }));
        return newSizes;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching sizes:", error);
      return [];
    }
  };

  const calculateTotalAmount = (
    tableItems: UniqueListItem<InventoryTransferFormItem>[]
  ): number => {
    return tableItems.reduce(
      (total, entry) =>
        total + (entry.item.unitValue || 0) * (entry.item.allSizesAmount || 0),
      0
    );
  };

  const generateTableHeaders = (
    transferItems: UniqueListItem<InventoryTransferFormItem>[]
  ) => {
    const uniqueSizeNames: string[] = [];

    transferItems.forEach((item) => {
      if (item.item.sizes) {
        item.item.sizes.forEach((size) => {
          const sizeName = size?.sizeName;
          if (sizeName && !uniqueSizeNames.includes(sizeName)) {
            uniqueSizeNames.push(sizeName);
          }
        });
      }
    });

    uniqueSizeNames.sort((a, b) => {
      const aFloat = parseFloat(a);
      const bFloat = parseFloat(b);
      return aFloat - bFloat;
    });

    setTableColumns([...FIXED_COLUMNS, ...uniqueSizeNames, "Acciones"]);
  };

  const getOriginAndDestination = async () => {
    if (
      initialInventoryTransfer === undefined ||
      initialInventoryTransfer.id === undefined
    ) {
      setInventoryTransderItems([]);
      setTotalQuantity(0);
      return [];
    }

    const originStatus = await getItem<InventoryLocation>(
      `/inventory_locations/${initialInventoryTransfer.originInventoryId}/`
    );

    const destinationStatus = await getItem<InventoryLocation>(
      `/inventory_locations/${initialInventoryTransfer.destinationInventoryId}/`
    );

    const newEditingItem = { ...editingInventoryTransfer };
    if (originStatus.status === SUCCESS) {
      if (originStatus.data !== undefined) {
        newEditingItem.originInventory = originStatus.data;
        setOriginLocation(originStatus.data);
      }
    }

    if (destinationStatus.status === SUCCESS) {
      if (destinationStatus.data !== undefined) {
        newEditingItem.destinationInventory = destinationStatus.data;
        setDestinationLocation(destinationStatus.data);
      }
    }

    setEditingInventoryTransfer(newEditingItem);
  };

  const getInventoryTransferItems = async () => {
    if (
      initialInventoryTransfer === undefined ||
      initialInventoryTransfer.id === undefined
    ) {
      setInventoryTransderItems([]);
      setTotalQuantity(0);
      return [];
    }
    const inventoryTransferIdStr = initialInventoryTransfer.id.toString();
    if (inventoryTransferIdStr === undefined) {
      setInventoryTransderItems([]);
      setTotalQuantity(0);
      return [];
    }
    const limit = 2000;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("company_id", company.id!.toString());
    additionalParams.set("inventory_transfer_id", inventoryTransferIdStr);
    const itemsStatus = await getList<InventoryTransferItem>(
      `/inventory_transfers/items/`,
      limit,
      offset,
      additionalParams
    );
    if (itemsStatus.status === SUCCESS) {
      if (itemsStatus.data !== undefined) {
        const items = listToUniqueListItems(
          inventoryTransferAPIReceivedToTableFormat(itemsStatus.data.items)
        );
        let calculatedTotalQuantity = calculateTotalQuantity(items);
        setTotalQuantity(calculatedTotalQuantity);
        setInventoryTransderItems(items);
        generateTableHeaders(items);
      }
    } else {
      setInventoryTransderItems([]);
      setTotalQuantity(0);
    }
  };

  const onLoadFromRequestClick = async () => {
    if (!destinationLocation || !originLocation) return;
    let map = new Map();
    map.set("store_id", destinationLocation.storeId!.toString());
    map.set("request_status", "pending");
    const limit = 20000;
    const offset = 0;
    setSubmitting(true);
    const storeRequestsStatus = await getList<StoreRequest>(
      "/store_requests/",
      limit,
      offset,
      map
    );
    if (storeRequestsStatus.status !== SUCCESS) {
      const message = storeRequestsStatus.detail
        ? storeRequestsStatus.detail
        : "Error desconocido";
      warningAlert(message);
      setSubmitting(false);
      return;
    }

    if (storeRequestsStatus.data === undefined) {
      warningAlert("Ocurrió un error");
      setSubmitting(false);
      return;
    }
    if (storeRequestsStatus.data.items.length <= 0) {
      warningAlert("No hay pedidos pendientes de tienda!");
      setSubmitting(false);
      return;
    }
    processStoreRequestsToList(storeRequestsStatus.data.items);

    setSubmitting(false);
  };

  const processStoreRequestsToList = useCallback(
    async (items) => {
      let newInventoryTransferItems = [...inventoryTransferItems];

      for (let item of items) {
        if (item.productVariant) {
          const variant = item.productVariant;
          const amountReceived = item.amount || 0;
          const existingItemIndex = newInventoryTransferItems.findIndex(
            (entry) =>
              entry.item.clothingProductId === variant.productId &&
              entry.item.colorId === variant.colorId
          );

          if (existingItemIndex !== -1) {
            const existingItem = newInventoryTransferItems[existingItemIndex];

            const updatedSizes =
              existingItem.item.sizes?.map((size) => {
                if (size.sizeId === variant.sizeId) {
                  return {
                    ...size,
                    amount: (size.amount || 0) + amountReceived,
                  };
                }
                return size;
              }) || [];

            newInventoryTransferItems[existingItemIndex] = {
              ...existingItem,
              item: {
                ...existingItem.item,
                sizes: updatedSizes,
                allSizesAmount: updatedSizes.reduce(
                  (total, size) => total + (size.amount || 0),
                  0
                ),
              },
            };
          } else {
            let newSizes = await fetchAvailableSizesForEntry(
              variant.productId!,
              variant.colorId!
            );

            if (newSizes.length === 0) {
              continue;
            }

            const firstSizeVariant = newSizes[0].variant;

            newSizes = newSizes.map((size) => {
              if (size.sizeId === variant.sizeId) {
                return {
                  ...size,
                  amount: (size.amount || 0) + amountReceived,
                };
              }
              return size;
            });

            const newItem = newUniqueListItem<InventoryTransferFormItem>({
              companyId: firstSizeVariant?.companyId,
              clothingProductId: firstSizeVariant?.productId,
              clothingProduct: firstSizeVariant?.product,
              productCodeIdentifier: firstSizeVariant?.productCodeIdentifier,
              productName: firstSizeVariant?.productName,
              colorName: firstSizeVariant?.color,
              color: firstSizeVariant?.colorObject,
              colorId: firstSizeVariant?.colorId,
              unitValue: firstSizeVariant?.productionCost,
              sizes: newSizes,
              allSizesAmount: newSizes.reduce(
                (total, size) => total + (size.amount || 0),
                0
              ),
            });
            newInventoryTransferItems.push(newItem);
          }
        }
      }
      let calculatedTotalQuantity = calculateTotalQuantity(
        newInventoryTransferItems
      );
      setTotalQuantity(calculatedTotalQuantity);
      setInventoryTransderItems(newInventoryTransferItems);
      generateTableHeaders(newInventoryTransferItems);
      setEditingInventoryTransfer((prev) => ({
        ...prev,
        totalValue: calculateTotalAmount(newInventoryTransferItems),
      }));
    },
    [inventoryTransferItems]
  );

  const onSave = async () => {
    setSubmitting(true);
    if (
      originLocation !== null &&
      destinationLocation !== null &&
      originLocation.id === destinationLocation.id
    ) {
      errorAlert("Debe seleccionar inventarios distintos");
      setSubmitting(false);
      return;
    }
    let toSendInventoryTransfer: InventoryTransfer = {
      ...editingInventoryTransfer,
      originInventory: originLocation ? originLocation : undefined,
      destinationInventory: destinationLocation
        ? destinationLocation
        : undefined,
      companyId: company.id,
      status:
        editingInventoryTransfer.status === undefined
          ? "pending"
          : editingInventoryTransfer.status,
      senderUserId: currentUser ? currentUser.id : undefined,
      senderUserName: currentUser ? currentUser.email : undefined,
      items: inventoryTransferTableFormatToAPIReceived(
        uniqueListToListItems(inventoryTransferItems)
      ),
    };

    let requestPromise: Promise<ItemRequestStatus<InventoryTransfer>>;
    if (editingInventoryTransfer.id === undefined) {
      requestPromise = createItem<InventoryTransfer>(
        "/inventory_transfers/create/",
        toSendInventoryTransfer
      );
    } else {
      const updateUrl =
        toSendInventoryTransfer.status === "ongoing"
          ? `/inventory_transfers/${toSendInventoryTransfer.id}/late_update/`
          : `/inventory_transfers/${toSendInventoryTransfer.id}/`;
      requestPromise = updateItem<InventoryTransfer>(
        updateUrl,
        toSendInventoryTransfer
      );
    }

    const inventoryTransferStatus = await requestPromise;

    if (inventoryTransferStatus.status !== SUCCESS) {
      if (inventoryTransferStatus.errors !== undefined) {
        setErrors(inventoryTransferStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (inventoryTransferStatus.detail !== undefined) {
        message = inventoryTransferStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
      return;
    }
    setSubmitting(false);
  };

  const clearForm = () => {
    setEditingInventoryTransfer({});
    setInventoryTransderItems([]);
    setTotalQuantity(0);
    generateTableHeaders([]);
    setBarcodeToBeAdded("");
    setAmountToBeAdded(1);
    setEditable(true);
    if (barcodeInputRef.current !== null) {
      barcodeInputRef.current.focus();
    }
    setSendDate("");
    setShowStoreRequestsButton(false);
  };

  useEffect(() => {
    setEditingInventoryTransfer(
      initialInventoryTransfer ? initialInventoryTransfer : {}
    );

    const date = initialInventoryTransfer?.sendDate;
    setSendDate(date ? moment(date) : "");
    setEditable(
      inventoryTransferIsEditable(
        initialInventoryTransfer ? initialInventoryTransfer : {}
      )
    );
    getOriginAndDestination();
    getInventoryTransferItems();
    setShowStoreRequestsButton(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInventoryTransfer]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  const inventoryTransferItemsErrors = getFieldErrors(
    "items",
    errors
  ) as Errors[];

  return (
    <>
      <fieldset disabled={submitting || !editable}>
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={2}>
              <CLabel className={"mt-2"}>Fecha/Hora de envío:</CLabel>
            </CCol>
            <CCol>
              <Datetime
                className="pl-0"
                onChange={onSendDatehange}
                value={sendDate}
                locale="es/PY"
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                closeOnSelect={true}
              ></Datetime>
              <FieldErrors
                errors={getFieldErrors("orderDate", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Origen:</CLabel>
            </CCol>
            <CCol>
              <InventoryLocationSelect
                value={originLocation}
                onChange={onOriginChange}
                inventoryType={INVENTORY_LOCATION_TYPE_PRODUCTS}
                disabled={
                  !editable ||
                  editingInventoryTransfer.status === "ongoing" ||
                  editingInventoryTransfer.status === "pending"
                }
              ></InventoryLocationSelect>
              <FieldErrors
                errors={getFieldErrors("originName") as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CLabel>Destino:</CLabel>
            </CCol>
            <CCol>
              <InventoryLocationSelect
                value={destinationLocation}
                onChange={onDestinationChange}
                inventoryType={INVENTORY_LOCATION_TYPE_PRODUCTS}
                disabled={
                  !editable ||
                  editingInventoryTransfer.status === "ongoing" ||
                  editingInventoryTransfer.status === "pending"
                }
              ></InventoryLocationSelect>
              <FieldErrors
                errors={getFieldErrors("destinationName") as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {showStoreRequestsButton && editable ? (
          <CFormGroup>
            <CRow>
              <CCol md={12}>
                <CButton
                  className="btn btn-primary"
                  onClick={onLoadFromRequestClick}
                  disabled={submitting || !editable}
                >
                  Cargar Pedidos de Tienda
                </CButton>
              </CCol>
            </CRow>
          </CFormGroup>
        ) : (
          <></>
        )}
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Monto Total:</CLabel>
            </CCol>
            <CCol md={2}>
              <CurrencyField
                currency={PYG}
                disabled={true}
                value={
                  editingInventoryTransfer.totalValue
                    ? editingInventoryTransfer.totalValue
                    : 0
                }
              ></CurrencyField>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Observación:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(
                  editingInventoryTransfer.senderObs
                )}
                onChange={onObsChange}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("obs", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2} lg={1} sm={2} className={"mt-2"}>
              <CLabel>Cantidad:</CLabel>
            </CCol>
            <CCol md={2} lg={2} xl={1}>
              <CInput
                type="number"
                value={amountTobeAdded}
                onChange={onAmountChange}
                min={1}
                placeholder="Cantidad"
              ></CInput>
            </CCol>
            <CCol md={2} lg={2} xl={1} className={"mt-2"}>
              <CLabel>Cód. de barras:</CLabel>
            </CCol>
            <CCol md={3} lg={2}>
              <CInput
                innerRef={barcodeInputRef}
                type="text"
                value={barcodeTobeAdded}
                onChange={onBarcodeChange}
                onKeyDown={onBarcodeKeyDown}
                placeholder="Código de barras"
              ></CInput>
            </CCol>
            <CCol md={1}>
              <CButton
                className="btn btn-primary"
                onClick={onBarcodeSend}
                disabled={submitting || !editable}
              >
                Ingresar
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={12}>
              <h5>Productos</h5>
              <h5 className="float-right">{`Total de artículos: ${totalQuantity}`}</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <FieldErrors
                errors={getFieldErrors("items", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {tableColumns.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryTransferItems.map(
                      (inventoryTransferFormItem, ix) => {
                        return (
                          <InventoryTransferItemForm
                            disabled={!editable}
                            key={inventoryTransferFormItem.uuid}
                            value={inventoryTransferFormItem.item}
                            sizesColumns={tableColumns}
                            initialValue={inventoryTransferFormItem.item}
                            onDelete={() =>
                              onInventoryTransferFormItemEntryDelete(
                                inventoryTransferFormItem
                              )
                            }
                            onChange={(item) =>
                              onInventoryTransferFormItemEntryChange({
                                uuid: inventoryTransferFormItem.uuid,
                                item: item,
                              })
                            }
                            errors={
                              ix < inventoryTransferItemsErrors.length
                                ? inventoryTransferItemsErrors[ix]
                                : {}
                            }
                          />
                        );
                      }
                    )}
                    {inventoryTransferItems.length === 0 ? (
                      <tr>
                        <td colSpan={tableColumns.length}>
                          Agregue nuevas entradas con el boton + de la derecha
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-1">
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
                disabled={!editable}
              >
                <i className="fa fa-plus" />
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            {/*<CButton type="button" color="secondary" onClick={onClose}>
              Atras
                  </CButton>*/}
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default InventoryTransferForm;
