import IncomeExpenseSummaryList from "../components/income-expense-summary/IncomeExpenseSummaryList";

const IncomeExpenseSummaryPage = () => {
  return (
    <>
      <IncomeExpenseSummaryList />
    </>
  );
};

export default IncomeExpenseSummaryPage;
