import FullInventoryProductsList from "../components/inventory-products/FullInventoryProductsList";

const FullInventoryProductListPage = () => {
  return (
    <>
      <FullInventoryProductsList />
    </>
  );
};

export default FullInventoryProductListPage;
