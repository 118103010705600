import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { formatDateTime } from "./dates";
import { defaultValueOnUndefined } from "./fields";

import {
  ClientPayment,
  getPaymentStatusLabel,
  getPaymentTypeLabel,
} from "../models/client";

const lungorLogo = require("../assets/img/lungorlogo.jpg");

interface PaymentPDFEntry {
  date: string;
  clientName: string;
  clientRuc: string;
  amount: string;
  debtDiscount: string;
  status: string;
  paymentType: string;
  bank: string;
  checkNumber: string;
  checkDate: string;
  obs: string;
}

const convertClientPaymentsToPDFEntries = (
  payments: ClientPayment[]
): PaymentPDFEntry[] => {
  return payments.map((payment) => {
    return {
      date: payment.date ? formatDateTime(new Date(payment.date)) : "-",
      clientName: defaultValueOnUndefined("-", payment.clientName),
      clientRuc: defaultValueOnUndefined("-", payment.clientRuc),
      amount: payment.amountPaid ? payment.amountPaid.toLocaleString() : "-",
      debtDiscount: payment.discountInDebt
        ? payment.discountInDebt.toLocaleString()
        : "-",
      status: payment.status ? getPaymentStatusLabel(payment.status) : "-",
      paymentType: payment.paymentType ? getPaymentTypeLabel(payment) : "-",
      bank: defaultValueOnUndefined("-", payment.bankName),
      checkNumber: defaultValueOnUndefined("-", payment.checkNumber),
      checkDate: payment.checkDate
        ? formatDateTime(new Date(payment.checkDate))
        : "-",
      obs: defaultValueOnUndefined("-", payment.obs),
    };
  });
};

type FontStyle = "normal" | "bold" | "italic";

const generatePaymentReportPDF = async (
  payments: ClientPayment[],
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "a4",
  });
  const entries = convertClientPaymentsToPDFEntries(payments);

  const totals = calculateTotals(payments);

  const entriesPerPage = 25;
  const totalPages = Math.ceil(entries.length / entriesPerPage);

  const headerStyles = {
    fontSize: 5,
    font: "helvetica",
    fontStyle: "bold" as FontStyle,
    fillColor: "#ffffff",
    textColor: "#000000",
    lineWidth: 0.1,
  };

  const bodyStyles = {
    fontSize: 5,
    font: "helvetica",
  };

  for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
    if (currentPage > 1) {
      doc.addPage();
    }

    doc.setFontSize(10);
    generateHeader(doc, titlesMiddle, titlesRight);

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = Math.min(startIndex + entriesPerPage, payments.length);
    const pageEntries = entries.slice(startIndex, endIndex);

    if (currentPage === totalPages) {
      pageEntries.push(totals);
    }

    autoTable(doc, {
      theme: "grid",
      startY: 45,
      head: [
        [
          "Fecha",
          "Cliente",
          "RUC",
          "Monto (Gs)",
          "Descuento a deuda de cliente",
          "Estado",
          "Medio de Pago",
          "Banco",
          "Nro Cheque",
          "Fecha Cheque",
          "Obs",
        ],
      ],
      body: pageEntries.map((entry) => Object.values(entry)),
      headStyles: headerStyles,
      bodyStyles: bodyStyles,
    });

    doc.setFontSize(5);
    doc.text(
      `Página ${currentPage} de ${totalPages}`,
      14,
      doc.internal.pageSize.height - 10
    );
  }

  const blob = doc.output("blob");
  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
};

const calculateTotals = (payment: ClientPayment[]): PaymentPDFEntry => {
  const priceTotal: number = payment.reduce(
    (sum, item) => sum + (item.amountPaid || 0),
    0
  );

  const priceDiscountTotal: number = payment.reduce(
    (sum, item) => sum + (item.discountInDebt || 0),
    0
  );

  return {
    date: "",
    clientName: "",
    clientRuc: "",
    amount: priceTotal.toLocaleString(),
    debtDiscount: priceDiscountTotal.toLocaleString(),
    status: "",
    paymentType: "",
    bank: "",
    checkNumber: "",
    checkDate: "",
    obs: "",
  };
};

const generateHeader = (
  doc: jsPDF,
  titlesMiddle: string[],
  titlesRight: string[]
) => {
  const x = 14;
  const y = 10;
  const width = doc.internal.pageSize.width - 28;
  const height = 30;
  const middleX = x + width / 2;

  doc.rect(x, y, width, height);

  const img = new Image();
  img.src = lungorLogo;
  doc.addImage(img, "JPEG", x + 5, y + 5, 35, 20);

  const totalTitlesMiddle = titlesMiddle.length;
  const totalTitlesRight = titlesRight.length;
  const totalTitles = Math.max(totalTitlesMiddle, totalTitlesRight);

  const verticalSpace = height / (totalTitles + 1);

  let middleTitleY = y + verticalSpace;

  titlesMiddle.forEach((title) => {
    const middleTitleWidth = doc.getTextWidth(title);
    const middleTitleX = middleX - middleTitleWidth / 2;
    doc.text(title, middleTitleX, middleTitleY);
    middleTitleY += verticalSpace;
  });

  doc.setFontSize(6);
  let rightTitleY = y + verticalSpace;

  const rightX = x + width;
  titlesRight.forEach((title) => {
    const rightTitleWidth = doc.getTextWidth(title);
    const rightTitleX = rightX - rightTitleWidth - 5;
    doc.text(title, rightTitleX, rightTitleY);
    rightTitleY += verticalSpace;
  });
};
export default generatePaymentReportPDF;
